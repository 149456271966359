const Hero = ()=>{
    return (
    <div className = 'hero' id = 'hero'>
<div className="hero__text">
  <h1 className="hero__title">Garrett Anderson</h1>
  <h2 className="hero__subtitle">Developer</h2>
</div>
</div>
    )
}

export default Hero;